<template>
    <div class="List">
        <div v-for="(item,index) in list" class="item" :key="index" v-show="item.isShow === undefined? true : item.isShow">
            <router-link :to="{ path: item.uRI, query: g_setQuery()}" tag="div" class="box">
                <div class="icon iconfont" @click="handleClick(item)" :class="item.iconURI" :style="'color:'+item.color"> </div>
                <div class="name">{{item.display}}</div>
            </router-link>
        </div>
    </div>
</template>
<script>
import { getIsApp } from '@/libs/utils'
import hybrid from '@/libs/hybrid'
export default {
    name: 'List',
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {

        }
    },
    methods: {
        handleClick(item) {
            if (item.iconURI == 'icon-kehugenjin') {
                hybrid.customerFollow()
            } else if (item.iconURI == 'icon-guanyu') {
                hybrid.aboutMX()
            } else if (item.iconURI == 'icon-baojiadan') {
                hybrid.goOfferingList()
            } else if (item.iconURI == 'icon-xiaoshoudingdan') {
                hybrid.goSaleList()
            } else if (item.iconURI == 'icon-H5-caigouhetong') {
                hybrid.goPurchaseContract()
            } else if (item.flag == 'toWriteLog') {
                if (getIsApp()) {
                    hybrid.toWriteLog()
                } else {
                    this.$router.push('/salelog/writeLog')
                }
            } else if (item.flag == 'toLookLog') {
                if (getIsApp()) {
                    hybrid.toLookLog()
                } else {
                    this.$router.push('/salelog/viewLog')
                }
            }
        }
    },
    components: {

    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
@import "zh-cn.less";
@import "en.less";
</style>
