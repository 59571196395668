<template>
    <div class='App'>
        <collapse v-for="(item,index) in apps" :key="index" class="AppItem" :title="item.title">
            <list :list="item.urls"></list>
        </collapse>
    </div>
</template>
<script>
import Collapse from '@/components/Collapse/index'
import List from './List/index'
import titleMixin from '@/mixin/title'
import { getItem, getIsApp, isGongChuang } from '@/libs/utils'
import hybrid from '@/libs/hybrid'
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'App',
    mixins: [titleMixin],
    title: function fc() { return this.$t('mx_mobile.App.1584681570248') }, // '应用'
    data() {
        return {
            apps: [
                // {
                //     title: '业务',
                //     urls: [
                //         {
                //             appCode: 'A002',
                //             display: '线索',
                //             iconURI: 'icon-clue',
                //             moduleCode: 'PS003',
                //             naviCode: 'NSC022',
                //             parentNaviCode: 'NV011',
                //             sortOrder: 10,
                //             subNavis: [],
                //             color: '#909399',
                //             uRI: '/sale/tabs/list/PS003',
                //             viewLayout: 'classicListQuota',
                //             viewType: 'list'
                //         }
                //     ]
                // },
                {
                    title: this.$t('mx_mobile.App.1584682419600'), // '知识管理'
                    urls: [
                        {
                            appCode: 'A005',
                            display: this.$t('mx_mobile.App.1584682584688'), // '文档搜索'
                            iconURI: 'icon-search',
                            moduleCode: '',
                            naviCode: 'NVD001',
                            parentNaviCode: 'NV006',
                            sortOrder: 1,
                            subNavis: [],
                            color: '#FF7165',
                            uRI: '/document/search'
                        },
                        {
                            appCode: 'A005',
                            display: this.$t('mx_mobile.App.1584682646398'), // '我的文档'
                            iconURI: 'icon-document',
                            moduleCode: '',
                            naviCode: 'NVD001',
                            parentNaviCode: 'NV006',
                            sortOrder: 1,
                            subNavis: [],
                            color: '#FF7165',
                            uRI: '/document/mydocument'
                        },
                        {
                            appCode: 'A005',
                            display: this.$t('mx_mobile.App.1584682669241'), // '知识库'
                            iconURI: 'icon-book',
                            moduleCode: '',
                            naviCode: 'NVD001',
                            parentNaviCode: 'NV006',
                            sortOrder: 1,
                            subNavis: [],
                            color: '#5EA3F6',
                            uRI: '/document/knowledgebase'
                        },
                        {
                            appCode: 'A005',
                            display: this.$t('mx_mobile.App.1584682691693'), // '已删除文档'
                            iconURI: 'icon-del',
                            moduleCode: '',
                            naviCode: 'NVD001',
                            parentNaviCode: 'NV006',
                            sortOrder: 1,
                            subNavis: [],
                            color: '#FFB735',
                            uRI: '/document/deletefile'
                        }
                    ]
                },
                {
                    title: this.$t('mx_mobile.App.1584682559389'), // '日志'
                    urls: [
                        {
                            appCode: 'A005',
                            display: this.$t('mx_mobile.App.1584682749384'), // '写日志'
                            iconURI: 'icon-edit',
                            moduleCode: '',
                            naviCode: 'NVD001',
                            parentNaviCode: 'NV006',
                            sortOrder: 1,
                            subNavis: [],
                            color: '#FFB735',
                            uRI: '',
                            flag: 'toWriteLog'
                        },
                        {
                            appCode: 'A005',
                            display: this.$t('mx_mobile.App.1584682777300'), // '看日志'
                            iconURI: 'icon-task',
                            moduleCode: '',
                            naviCode: 'NVD001',
                            parentNaviCode: 'NV006',
                            sortOrder: 1,
                            subNavis: [],
                            color: '#B99BFF',
                            uRI: '',
                            flag: 'toLookLog'
                        },
                        {
                            appCode: 'A005',
                            display: this.$t('mx_mobile.App.1584682801330'), // '统计'
                            iconURI: 'icon-count',
                            moduleCode: '',
                            naviCode: 'NVD001',
                            parentNaviCode: 'NV006',
                            sortOrder: 1,
                            subNavis: [],
                            color: '#80F19E',
                            uRI: '/salelog/statisticsLog'
                        }
                    ]
                },
                {
                    title: this.$t('mx_mobile.App.1596628342416'), // '商品'
                    urls: [
                        {
                            appCode: 'A005',
                            display: this.$t('mx_mobile.App.1596628387180'), // '商品管理'
                            iconURI: 'icon-goods',
                            moduleCode: '',
                            naviCode: 'NVD001',
                            parentNaviCode: 'NV006',
                            sortOrder: 1,
                            subNavis: [],
                            color: '#5EA3F6',
                            uRI: '/goods/goodsList'
                        }
                    ]
                },
                {
                    title: this.$t('mx_mobile.App.1584682882655'), // '我的MX'
                    urls: [
                        {
                            appCode: 'A005',
                            display: this.$t('mx_mobile.App.1584683189943'), // '个人中心'
                            iconURI: 'icon-my',
                            moduleCode: '',
                            naviCode: 'NVD001',
                            parentNaviCode: 'NV006',
                            sortOrder: 1,
                            subNavis: [],
                            color: '#FF7165',
                            uRI: '/my/center'
                        }, {
                            appCode: 'A005',
                            display: this.$t('mx_mobile.App.1584683241860'), // '我的设置'
                            iconURI: 'icon-setting',
                            moduleCode: '',
                            naviCode: 'NVD001',
                            parentNaviCode: 'NV006',
                            sortOrder: 1,
                            subNavis: [],
                            color: '#FFB735',
                            uRI: '/my/setting'
                        }
                    ]
                }
            ],
            isFuma: false, // 登录的是否为孚盟
            appIconList: Object.freeze([])
        }
    },
    async created() {
        await this.getAuthorizationModuleCode()
        this.addClientFollow()
        this.addPriceCalculation()
        this.addAboutMX()
        await this.checkFuma()
        // 邮件监视器
        let mailmonitorModule = {
            title: this.$t('mx_mobile.App.1584683396981'), // '邮件监视器'
            urls: [
                {
                    appCode: 'A005',
                    display: this.$t('mx_mobile.App.1584683396981'), // '邮件监视器'
                    iconURI: 'icon-task',
                    moduleCode: '',
                    naviCode: 'NVD001',
                    parentNaviCode: 'NV006',
                    sortOrder: 1,
                    subNavis: [],
                    color: '#FF7165',
                    uRI: '/mailmonitor/screen'
                }
                // {
                //     appCode: 'A005',
                //     display: '邮箱任务',
                //     iconURI: 'icon-task',
                //     moduleCode: '',
                //     naviCode: 'NVD001',
                //     parentNaviCode: 'NV006',
                //     sortOrder: 1,
                //     subNavis: [],
                //     color: '#FF7165',
                //     uRI: '/my/center'
                // },
                // {
                //     appCode: 'A005',
                //     display: '超时邮件查询',
                //     iconURI: 'icon-task',
                //     moduleCode: '',
                //     naviCode: 'NVD001',
                //     parentNaviCode: 'NV006',
                //     sortOrder: 1,
                //     subNavis: [],
                //     color: '#FF7165',
                //     uRI: '/my/center'
                // }
            ]
        }
        this.isFuma && this.apps.splice(3, 0, mailmonitorModule)
    },
    mounted() {
        // this.quickAdd() // 全局右上角快捷新增
        this.setMenu()
        this.setSysBoxValue()
    },
    computed: {
        ...mapGetters(['companie'])
    },
    methods: {
        async getAuthorizationModuleCode() {
            let list = []
            let temp = []
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.getAuthorizationModuleCode
                let res = await this.axios.get(url)
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    list = res.data.data
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                this.$toast.fail(this.Global.config.errorTitle)
            }
            list.forEach(v => {
                temp.push(v.moduleCode)
            })
            this.appIconList = temp
        },
        // 个人信息存起来
        ...mapActions([
            'setSysBoxValue'
        ]),
        addPriceCalculation() {
            if (isGongChuang(this.companie.cId)) {
                console.log('this.apps', this.apps)
                let hasflag = false
                const obj = {
                    appCode: 'A082',
                    cnName: this.$t('mx_mobile.common.1665802307830'), // '草坪算价'
                    display: this.$t('mx_mobile.common.1665802307830'), // '草坪算价'
                    enName: 'Price calculation',
                    iconURI: 'icon-a-suanjia2020',
                    naviCode: 'NV022',
                    parentNaviCode: '0',
                    sortOrder: 1,
                    subNavis: [],
                    uRI: '/PriceCalc'
                }
                this.apps.forEach(el => {
                    // '业务'
                    if (el.title === this.$t('mx_mobile.common.1665802359812')) {
                        hasflag = true
                        el.urls.push(obj)
                    }
                })
                if (!hasflag) {
                    this.apps.unshift({
                        title: this.$t('mx_mobile.common.1665802359812'), // '业务'
                        urls: [obj]
                    })
                }
            }
        },
        addClientFollow() {
            if (getIsApp() && getItem('isNewVersion') == 1) {
                this.apps.unshift({
                    title: this.$t('mx_mobile.common.1665802359812'), // '业务'
                    urls: [
                        {
                            appCode: 'A005',
                            display: this.$t('mx_mobile.common.1665802414452'), // '客户跟进'
                            iconURI: 'icon-kehugenjin',
                            moduleCode: 'BF004',
                            naviCode: 'NVD001',
                            parentNaviCode: 'NV006',
                            sortOrder: 1,
                            subNavis: [],
                            color: '#FB4868',
                            isShow: this.appIconList.includes('BF004'),
                            uRI: ''
                        },
                        {
                            appCode: 'A002',
                            display: this.$t('mx_mobile.common.1665802470469'), // '线索'
                            iconURI: 'icon-H5-xiansuo',
                            moduleCode: 'PS003',
                            naviCode: 'NSC022',
                            parentNaviCode: 'NV011',
                            sortOrder: 10,
                            subNavis: [],
                            color: '#F27D07',
                            isShow: this.appIconList.includes('PS003'),
                            viewLayout: 'classicListQuota',
                            viewType: 'list',
                            uRI: '/sale/tabs/list/PS003'
                        },
                        {
                            appCode: 'A005',
                            display: this.$t('mx_mobile.common.1665802504653'), // '报价单'
                            iconURI: 'icon-baojiadan',
                            moduleCode: 'SC001',
                            naviCode: 'NVD001',
                            parentNaviCode: 'NV006',
                            sortOrder: 1,
                            subNavis: [],
                            color: '#008CEE',
                            isShow: this.appIconList.includes('SC001'),
                            uRI: ''
                        },
                        {
                            appCode: 'A005',
                            display: this.$t('mx_mobile.common.1665802540244'), // '销售订单'
                            iconURI: 'icon-xiaoshoudingdan',
                            moduleCode: 'SC002',
                            naviCode: 'NVD001',
                            parentNaviCode: 'NV006',
                            sortOrder: 1,
                            subNavis: [],
                            color: '#12B212',
                            isShow: this.appIconList.includes('SC002'),
                            uRI: ''
                        },
                        {
                            appCode: 'A005',
                            display: this.$t('mx_mobile.common.1665802569740'), // '采购合同'
                            iconURI: 'icon-H5-caigouhetong',
                            moduleCode: 'PC018',
                            naviCode: 'NVD001',
                            parentNaviCode: 'NV006',
                            sortOrder: 1,
                            subNavis: [],
                            color: '#FB2148',
                            isShow: this.appIconList.includes('PC018'),
                            uRI: ''
                        }
                    ]
                })
            }
        },
        async addAboutMX() {
            if (getIsApp() && await hybrid.checkVersionNew(217, 20214)) {
                this.apps.forEach(v => {
                    // '我的MX'
                    if (v.title == this.$t('mx_mobile.App.1584682882655')) {
                        v.urls.push(
                            {
                                appCode: 'A005',
                                display: this.$t('mx_mobile.common.1665802621830'), // '关于MX'
                                iconURI: 'icon-guanyu',
                                moduleCode: '',
                                naviCode: 'NVD001',
                                parentNaviCode: 'NV006',
                                sortOrder: 1,
                                subNavis: [],
                                color: '#FB2148',
                                uRI: ''
                            })
                    }
                })
            }
        },
        setMenu() {
            this.Global.utils.rightMenu.clearMenu()
        },
        async checkFuma() {
            await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.manageFlag, { params: {} }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.isFuma = res.data.data.manageFlag
                } else {
                    this.$toast(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        }
    },
    components: {
        collapse: Collapse,
        list: List
    },
    watch: {
        $route() {
            let path = this.$route.path
            if (path === '/app' || path === '/app/') {
                this.setMenu() // 全局右上角快捷新增
            }
        }
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
.App {
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: scroll;
    .AppItem {
        .margin(12px, 0);
    }
}
</style>
