<template>
    <div class="collapse">
        <div class="collapse_header" :class="{'van-hairline--bottom':isOpen}">
            <div class="title">{{title}}</div>
            <!-- '收起' '展开' -->
            <div class="button" @touchstart.stop="bodyShow">{{isOpen?$t('mx_mobile.common.1584683536248'):$t('mx_mobile.common.1584683570755')}}</div>
        </div>
        <div ref="collapseBody" class="collapse_body">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Collapse',
    props: {
        title: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            isOpen: true,
            isOnce: true
        }
    },
    methods: {
        bodyShow() {
            /*  this.isOpen = !this.isOpen */
            if (this.isOpen && this.isOnce) {
                this.isOnce = false
                this.height = this.$refs.collapseBody.clientHeight + 'px'
            }
            if (this.isOpen) {
                this.$refs.collapseBody.style.height = this.height
                window.requestAnimationFrame(() => {
                    this.$refs.collapseBody.style.height = '0px'
                    this.isOpen = !this.isOpen
                })
            } else {
                this.isOpen = !this.isOpen
                this.$refs.collapseBody.style.height = '0px'
                window.requestAnimationFrame(() => {
                    this.$refs.collapseBody.style.height = this.height
                })
            }
        }
    },
    components: {

    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
